import React from "react";
import Logo2 from "../assets/Img/Logo2.svg";
import icon1 from "../assets/Img/Instagram.svg";
import icon2 from "../assets/Img/Twitter.svg";
import icon3 from "../assets/Img/Youtube.svg";
import icon4 from "../assets/Img/iconnnnn.png";

export default function Footer() {
  return (
    <footer>
    <div className="footer">
    <div className="Footer-Logo">
        <img src={Logo2} alt="" className="Logo2-image" width="100%" />
      </div>
      <div className="Footer-Text">
        <p>© 2024 Rockbottom Initiative. All rights reserved</p>
      </div>
      <div className="Footer-Icons">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/rockbottominitiative?igsh=Y3FwZmdvdDh1Zmhu"
        >
          <img src={icon1} alt="" className="Icons-image" width="100%" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://x.com/tweetrockbottom?s=21&t=OGnmOnElTrnjW-TfNbWv6w"
        >
          <img src={icon2} alt="" className="Icons-image" width="100%" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/share/QBNRvaLZr7qk3i7C/?mibextid=kFxxJD"
        >
          <img src={icon3} alt="" className="Icons-image" width="100%" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sphiderassweb.org"
        >
          <img
            src={icon4}
            alt=""
            className="Icons-image spider-ass-icon-footer"
            width="100%"
          />
        </a>
      </div>
    </div>
    </footer>
  );
}
