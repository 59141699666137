import { create } from "zustand";
import axios from "axios";
import useBackendStore from "./auth-store";

const API_URL = process.env.REACT_APP_API_URL;
const accessToken = useBackendStore.getState().accessToken;

const usePostStore = create((set, get) => ({
  posts: [],
  isLiking: false,
  error: null,
  setPosts: (newPosts) => {
    if (!Array.isArray(newPosts)) {
      newPosts = [];
    }
    let currentPosts = get().posts;
    const combinedPosts = [...currentPosts, ...newPosts];

    const uniquePosts = combinedPosts
      .reduce((acc, current) => {
        const duplicate = acc.find((post) => post._id === current._id);
        if (!duplicate) {
          return [...acc, current];
        } else {
          return acc;
        }
      }, [])
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    set({ posts: uniquePosts });
  },
  likePost: async (postId) => {
    set({ isLiking: true });
    const state = get();
    const postIndex = state.posts.findIndex((post) => post._id === postId);
    if (postIndex < 0) return;

    const newPost = { ...state.posts[postIndex] };
    if (newPost.userLikes) {
      newPost.likeCount--;
      newPost.userLikes = false;
    } else {
      newPost.likeCount++;
      newPost.userLikes = true;
    }

    const newPosts = Array.isArray(state.posts) ? [...state.posts] : [];
    newPosts[postIndex] = newPost;
    set({ posts: newPosts });

    try {
      if (newPost.userLikes) {
        await addLike(postId);
      } else {
        await removeLike(postId);
      }
    } catch (error) {
      set({ error });
      newPost.likeCount = state.posts[postIndex].likeCount;
      newPost.userLikes = state.posts[postIndex].userLikes;
      newPosts[postIndex] = newPost;
      set({ posts: newPosts });
    } finally {
      set({ isLiking: false });
    }
  },
  commentPost: async (postId, comment) => {
    set({ isCommenting: true });
    const state = get();
    const postIndex = state.posts.findIndex((post) => post._id === postId);
    if (postIndex < 0) return;

    const newPost = { ...state.posts[postIndex] };
    newPost.commentCount++;

    const newPosts = Array.isArray(state.posts) ? [...state.posts] : [];
    newPosts[postIndex] = newPost;
    set({ posts: newPosts });

    try {
      await addComment(postId, comment);
    } catch (error) {
      set({ error });
      newPost.commentCount--;
      newPosts[postIndex] = newPost;
      set({ posts: newPosts });
    } finally {
      set({ isCommenting: false });
    }
  },
  deletePost: async (postId) => {
    const state = get();
    const newPosts = Array.isArray(state.posts) ? [...state.posts] : [];
    const postIndex = newPosts.findIndex((post) => post._id === postId);
    if (postIndex < 0) return;

    newPosts.splice(postIndex, 1);
    set({ posts: newPosts });

    try {
      await axios.delete(`${API_URL}/post/${postId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch (error) {
      set({ error });
      newPosts.splice(postIndex, 0, state.posts[postIndex]);
      set({ posts: newPosts });
    }
  },
}));

async function addComment(post_id, comment) {
  try {
    const response = await axios.post(
      `${API_URL}/post-comment`,
      { post: post_id, body: comment },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function addLike(postId) {
  try {
    const response = await axios.post(
      `${API_URL}/post-like`,
      { post: postId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function removeLike(postId) {
  try {
    const response = await axios.delete(`${API_URL}/post-like?post=${postId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default usePostStore;
