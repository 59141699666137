import axios from "axios";
import { useCallback, useState } from "react";
import useBackendStore from "../store/auth-store";

const useChat = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const accessToken = useBackendStore((state) => state.accessToken);
  const [error, setError] = useState(null);

  const getConversations = useCallback(
    async (query, lastUpdatedAt) => {
      try {
        const params = query ? { q: query, lastUpdatedAt } : { lastUpdatedAt };
        const response = await axios.get(`${API_URL}/chat`, {
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [API_URL, accessToken]
  );

  const getChatMessages = useCallback(
    async ({ from, to }) => {
      try {
        const response = await axios.get(`${API_URL}/chat/message`, {
          params: { from, to },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [API_URL, accessToken]
  );

  return { getConversations, getChatMessages, error };
};

export default useChat;
