import React from "react";
import { Link } from "react-router-dom";
import "./ErrorPage.scss";

const ErrorPage = ({ error, resetErrorBoundary }) => {
  return (
    <div className="Error-Container">
      <div className="Error-Content">
        <h1>Something went wrong.</h1>
        <p>We're sorry, but something went wrong. Please try again later.</p>
        <Link to="/" className="Home-btn">
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
