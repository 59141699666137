import React from "react";
import { useCommentStore } from "./Comment.state";
import { BsFillSendFill } from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import "./Comment.scss";

const Comment = ({ addComment, postId, isOpen, toggle }) => {
  const comment = useCommentStore((state) => state.comment);
  const setComment = useCommentStore((state) => state.setComment);

  const handleCommentSubmit = async () => {
    if (!postId) return;
    if (!comment) return;
    addComment(postId, comment);
    setComment("");
    if (toggle) {
      toggle();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`comment ${isOpen ? "open" : ""}`}>
      {toggle && (
        <button className="toggle" onClick={toggle}>
          <TfiClose />
        </button>
      )}
      <div className="comment-box">
        <textarea
          placeholder="Write a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={300}
        ></textarea>
        <button className="submit-button" onClick={handleCommentSubmit}>
          <BsFillSendFill />
        </button>
      </div>
    </div>
  );
};

export default Comment;
