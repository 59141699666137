import "./UserPageNav.scss";
import { GoHome } from "react-icons/go";
import { FaRegCircleUser } from "react-icons/fa6";
import { RiStickyNoteAddLine } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoExitOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNotification } from "../../contexts/notificationsContext";
import useAuth from "../../hooks/useAuth";

const UserPageNav = () => {
  const { logout } = useAuth();
  const { notifications } = useNotification;
  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (notifications?.length > 0) {
    }
  }, [notifications]);

  return (
    <section className="user-page-nav">
      <div className="user-page-nav-div">
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/home">
              <div className="user-page-nav-icon-div">
                <span className="user-page-nav-icon">
                  <GoHome />
                </span>
                <span className="user-page-nav-icon-text">Home</span>
              </div>
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/profile">
              <div className="user-page-nav-icon-div">
                <span>
                  <FaRegCircleUser className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">Profile</span>
              </div>
            </Link>
          </div>
        </section>

        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/create-post">
              <div className="user-page-nav-icon-div">
                <span>
                  <RiStickyNoteAddLine className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">Create Post</span>
              </div>
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/chat">
              <div className="user-page-nav-icon-div">
                <span>
                  <MdOutlineMailOutline className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">Messages</span>
              </div>
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <button className="log" onClick={handleLogout}>
              <span>
                <IoExitOutline className="user-page-nav-icon" />
              </span>
              <span> Log Out </span>
            </button>
          </div>
        </section>
      </div>
    </section>
  );
};
export default UserPageNav;
