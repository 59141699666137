import { useCallback } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

const useNotifications = () => {
  const accessToken = useBackendStore((state) => state.accessToken);
  const API_URL = process.env.REACT_APP_API_URL;

  const setFCMToken = useCallback(
    async (token) => {
      try {
        const response = await axios.put(
          `${API_URL}/users/fcm-token`,
          { fcmToken: token },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error("Failed to set FCM token");
      }
    },
    [accessToken, API_URL]
  );

  const getUserNotifications = useCallback(
    async (lastCreatedAt) => {
      const params = lastCreatedAt ? { lastCreatedAt } : {};
      try {
        const response = await axios.get(`${API_URL}/notification`, {
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        throw new Error("Failed to get user notifications");
      }
    },
    [accessToken, API_URL]
  );

  return { setFCMToken, getUserNotifications };
};

export default useNotifications;
