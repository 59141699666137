import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";
import { useId } from "../utils/decode-token";

export const FriendsContext = createContext();

export const FriendsProvider = ({ children }) => {
  const [friends, setFriends] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshFriends, setRefreshFriends] = useState(false);
  const [refreshSentRequests, setRefreshSentRequests] = useState(false);
  const accessToken = useBackendStore((store) => store.accessToken);
  const API_URL = process.env.REACT_APP_API_URL;
  const userId = useId();

  useEffect(() => {
    const fetchFriends = async () => {
      const response = await axios.get(
        `${API_URL}/friend?status=accepted&with=${userId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setFriends(response.data);
    };

    const fetchSentRequests = async () => {
      const response = await axios.get(
        `${API_URL}/friend?status=pending&user=${userId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setSentRequests(response.data);
    };

    Promise.all([fetchFriends(), fetchSentRequests()])
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [API_URL, accessToken, refreshFriends, refreshSentRequests, userId]);

  return (
    <FriendsContext.Provider
      value={{
        friends,
        sentRequests,
        loading,
        error,
        refresh: () => setRefreshFriends((prev) => !prev),
        refreshSentRequests: () => setRefreshSentRequests((prev) => !prev),
      }}
    >
      {children}
    </FriendsContext.Provider>
  );
};

export const useFriends = () => {
  const context = React.useContext(FriendsContext);
  if (context === undefined) {
    throw new Error("useFriends must be used within a FriendsProvider");
  }
  return context;
};
