import React, { useState } from "react";
import axios from "axios";
import line from "../volunteer/image2.jpg";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL: API_URL });
const Volunteer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await api.post("/volunteer", formData);
    } catch (error) {}
  };

  return (
    <div className="Volunteer-Container">
      <div className="Volunteer-Img">
        <img src={line} alt="" className="Volunteer-image" width="100%" />
      </div>
      <div className="Volunteer-Form">
        <div className="Volunteer-Text">
          <h3>Volunteer Today!</h3>
          <p>
            You are one step away from making an impact on the lives of people
            that need it every day
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="Form-Head">
            <div className="Form-Label">
              <label>First Name</label>
              <input
                type="text"
                className="Form-input"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="Form-Label">
              <label>Last Name</label>
              <input
                type="text"
                className="Form-input"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="Form-Label">
            <label>Email Address</label>
            <input
              type="email"
              className="Form-input"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="Form-Label">
            <label>Message</label>
            <textarea
              type="text"
              className="Form-textarea"
              placeholder="Type your message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <input
            type="submit"
            value={"Send a message"}
            className="Message-btn"
          />
        </form>
      </div>
    </div>
  );
};

export default Volunteer;
