import { useEffect, useState, useRef, useCallback } from "react";

const useInfiniteScroll = ({ fetchData, hasMore, threshold = 0.8 }) => {
  const [loading, setLoading] = useState(true);
  const observer = useRef();
  const initialFetch = useRef(true);

  const observerTarget = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading) {
            setLoading(true);
            fetchData().finally(() => setLoading(false));
          }
        },
        { threshold }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchData, threshold]
  );

  useEffect(() => {
    const initialLoad = async () => {
      if (initialFetch.current) {
        await fetchData();
        initialFetch.current = false;
        setLoading(false);
      }
    };

    initialLoad();
  }, [fetchData]);

  return { loading, observerTarget };
};

export default useInfiniteScroll;
