import "./PrivateProfile.scss";
import { BsBalloon } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import PostFeed from "../PostsFeed/PostFeed";
import useProfile from "../../hooks/useProfile";
import { useEffect, useState } from "react";
import { formatDateOfBirth, getFullYear } from "../../utils/resolve";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import { useId } from "../../utils/decode-token";

const PrivateProfile = () => {
  const { getProfile } = useProfile();
  const [profile, setProfile] = useState(null);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = useId();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.user);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [getProfile]);

  useEffect(() => {
    if (!profile && !loading) {
      toast.error("Profile not found", { toastId: "profile-not-found" });
    }
  }, [profile, loading]);

  if (loading) {
    return (
      <section className="loading-section">
        <div className="loading-container">
          <LogoSpinner className="loading-icon" />
        </div>
      </section>
    );
  }

  return (
    profile && (
      <div>
        <div className="private-profile-page">
          <div className="private-profile-page-container">
            <section className="private-profile-page-section">
              <div className="private-profile-details-section">
                <div className="profile-page-background">
                  <div className="curved-div">
                    <svg
                      width="100%"
                      height="300px"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0,100 C100,110 250,10 650,140 L700,00 L0,0 Z"
                        style={{ fill: "rgb(139, 1, 0)" }}
                      ></path>
                    </svg>
                  </div>

                  <div className="profile-page-edit-profile-picture-and-banner-edit">
                    <div className="profile-page-edit-background-image">
                      <div className="profile-page-edit-background-image-div">
                        <img
                          src={
                            profile?.image?.cover ??
                            placeholder("800", "200", "png", "background")
                          }
                          alt="background"
                        />
                      </div>
                    </div>
                    <div className="private-profile-page-profile-picture">
                      <div className="profile-page-edit-profile-picture-div">
                        <img
                          src={
                            profile?.image?.profile ??
                            placeholder(
                              "200",
                              "200",
                              "png",
                              profile?.name?.first[0]
                            )
                          }
                          alt="profile"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="profile-page-profile-details">
                    <div className="profile-page-profile-details-div">
                      <div className="profile-page-profile-name-and-username-div">
                        <h1 className="profile-page-profile-name">
                          {profile?.name?.first}
                        </h1>
                        <h5 className="profile-page-profile-username">
                          @{profile?.username}
                        </h5>
                      </div>
                      <div className="profile-page-profile-bio-div">
                        <span>{profile?.about}</span>
                      </div>
                      <div className="profile-page-profile-dates-div">
                        <div>
                          <BsBalloon />
                          Born {formatDateOfBirth(profile?.dob)}
                        </div>
                        <div>
                          <IoIosLink />

                          <span className="profile-page-profile-link">
                            <a href="www.google.com">www.google.com</a>
                          </span>
                        </div>
                        <div>
                          <LuCalendarDays />
                          Joined {getFullYear(profile?.createdAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="profile-page-post-and-story-section">
                  <div className="profile-page-post-and-story-section-div">
                    <div className="profile-page-post-section">
                      <h1>Post</h1>
                    </div>
                    <div>
                      <PostFeed userId={userId} key={userId} showDelete />
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  );
};
export default PrivateProfile;
