import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useBackendStore from "../store/auth-store";
import { useAuthContext } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const navigate = useNavigate();
  const { resetAuth } = useAuthContext();
  const [ispending, setIspending] = useState(false);
  const accessToken = useBackendStore((state) => state.accessToken);
  const setAccessToken = useBackendStore((state) => state.setAccessToken);
  const setDob = useBackendStore((state) => state.setDob);
  const setEmail = useBackendStore((state) => state.setEmail);
  const setName = useBackendStore((state) => state.setAccessToken);
  const resetToken = useBackendStore((state) => state.resetToken);

  const source = axios.CancelToken.source();

  const cancelRequest = () => {
    source.cancel("Request cancelled");
  };

  const API_URL = process.env.REACT_APP_API_URL;

  const apiUrl = `${API_URL}/auth`;

  const signup = async (
    firstName,
    lastName,
    username,
    email,
    dob,
    genotype,
    password
  ) => {
    setIspending(true);
    try {
      const postData = {
        firstName,
        lastName,
        username,
        email,
        dob,
        password,
        genotype,
        confirmPassword: password,
      };

      const res = await axios.post(`${apiUrl}/signup`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      });

      const data = res.data;

      if (data.token) {
        setDob(dob);
        setEmail(email);
        setName(firstName + lastName);
        setAccessToken(data.token);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }
    } finally {
      setIspending(false);
    }
  };

  const login = async (email, password) => {
    setIspending(true);

    try {
      const postData = {
        email,
        password,
      };

      const res = await axios.post(`${apiUrl}/signin`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      });

      const data = res.data;
      if (data.token) {
        setAccessToken(data.token);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }
    } finally {
      setIspending(false);
    }
  };

  const logout = async () => {
    resetToken();
    localStorage.clear();
    navigate("/login");
    resetAuth();
  };

  const sendEmailVerification = async () => {
    setIspending(true);

    if (!accessToken) {
      setIspending(false);
      throw new Error("Unauthorized access");
    }

    try {
      await axios.get(`${apiUrl}/verify-email/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        cancelToken: source.token,
      });

      setIspending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }

      setIspending(false);
    }
  };

  const verifyOTP = async (otp) => {
    setIspending(true);

    try {
      const postData = {
        otp,
      };

      await axios.patch(`${apiUrl}/verify-email`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        cancelToken: source.token,
      });

      setIspending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }
    } finally {
      setIspending(false);
    }
  };

  const resetPassword = async (email) => {
    setIspending(true);

    try {
      const params = { email };
      await axios.get(`${apiUrl}/auth/password-reset`, {
        headers: {
          "Content-Type": "application/json",
        },
        params,
        cancelToken: source.token,
      });

      setIspending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }
    } finally {
      setIspending(false);
    }
  };

  const changePassword = async (token, password) => {
    setIspending(true);

    try {
      const postData = { password, token };
      await axios.post(`${apiUrl}/password-reset`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      });

      setIspending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error.response.data.message);
      }
    } finally {
      setIspending(false);
    }
  };

  return {
    signup,
    login,
    logout,
    sendEmailVerification,
    verifyOTP,
    resetPassword,
    changePassword,
    ispending,
    cancelRequest,
  };
};

export default useAuth;
