import React, { useEffect } from "react";
import line from "../assets/Img/Line.svg";
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);  // Register ScrollTrigger plugin

export default function Faq() {
  const navigate = useNavigate();

  useEffect(() => {
    const elements = document.querySelectorAll('.fk');

    elements.forEach((element) => {
      gsap.fromTo(
        element,
        {
          opacity: 0,  // Initially invisible
          y: 100,     // Start below the viewport
        },
        {
          scrollTrigger: {
            trigger: element,
            start: 'top 90%', // Animation starts when the top of the element is 80% in the viewport
            end: 'bottom 50%', // End when the bottom of the element is at 50% viewport height
            toggleActions: 'play none none none', // Only play once when entering
            // scrub: true,  // Uncomment if you want smooth scroll-based animation
            // markers: true // Uncomment to visualize the trigger points
          },
          y: 0,        // Move to its original position
          opacity: 1,  // Fade into view
          duration: 1, // Animation duration
          ease: 'power1.out',
        }
      );
    });

    // Cleanup function to kill all ScrollTriggers
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <div>
      <div className="Faq">
        <div className="Faq-text-one fk">
          <div className="line-faq">
            <img src={line} alt="" className="line-image" width="100%" />
            <h4>Who we are</h4>
          </div>
          <h2>We are a non-governmental organization</h2>
        </div>
        <div className="Faq-text-two fk">
          <h2>Why Rock Bottom Sickle Cell Initiative</h2>
          <div className="obj">
            <h6>OUR MISSION</h6>
            <p>Empower, Support, Educate</p>
          </div>
          <div>
            <h6>VISION STATEMENT</h6>
            <p>A world where every sickle cell warrior thrives</p>
          </div>
        </div>
      </div>

      <div className="Faq-two fk">
        <div className="Faq-two-text">
          <div className="two-text">
            <h2>Empowerment</h2>
            <p>
              We empower warriors by sharing knowledge, sharing stories, listening
              to stories, providing resources, embracing positivity and
              opportunities for personal and professional growth all by building a
              network/community.
            </p>
            <button
              onClick={() => navigate("/register")}
              className="Learn-btn"
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="green fk" id="Contact">
          <iframe
            src="https://drive.google.com/file/d/13-Ps4jrOotffykefXM65tMc-P3Z9glGx/preview"
            width="400"
            height="440"
            allow="autoplay"
            title="faq-embed"
          />
        </div>
      </div>
    </div>
  );
}
