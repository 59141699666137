import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import useAuth from "../../hooks/useAuth";
import image3 from "../login/image 3.svg";
import "./login.scss";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, ispending } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      if (!toast.isActive("allFieldsRequired")) {
        toast.error("All fields are required", {
          toastId: "allFieldsRequired",
        });
      }
      return;
    }

    try {
      await login(email, password);
    } catch (error) {
      toast.error(error.message, {
        toastId: "loginError",
      });
    }
  };

  return (
    <div className="Login-Container">
      <div className="Login-Img">
        <img src={image3} alt="Login" className="Login-image" width="100%" />
      </div>
      <form className="Login-Form" onSubmit={handleSubmit}>
        <div className="Login-Start">
          <h1>Log in</h1>
          <p>
            <span> Don’t have an account? </span>
            <span id="Sign-up">
              <Link to="/register"> Sign Up </Link>
            </span>
          </p>
          <input
            type="email"
            placeholder="Email Address"
            className="Email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="Password">
            <input
              type="password"
              placeholder="Password"
              className="password-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="Logged-in-btn">
            {ispending ? <Spinner /> : "Login"}
          </button>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
}
