import React, { useEffect } from "react";
import one from "../assets/Img/New/EDO WOMEN.png";
import two from "../assets/Img/SphiderAssWebLogo__1_-removebg-preview.png";
import four from "../assets/Img/New/EDO 2.png";
import six from "../assets/Img/New/assisials icon.png";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export default function Logo() {
  useEffect(() => {
    // GSAP animation for smoother staggered appearance
    gsap.fromTo(
      ".Logo-image",
      {
        opacity: 0,    // Initially invisible
        // y: 100,         // Start slightly below its original position
        scale: 0.9     // Start slightly smaller for added effect
      },
      {
        opacity: 1,    // Fade into view
        // y: 0,          // Move to original position
        scale: 1,      // Scale back to normal
        duration: 1.5, // Animation duration for each image
        stagger: 0.5,  // 0.5 second delay between each image
        ease: "power3.out", // Smooth easing for gradual appearance
        scrollTrigger: {
          trigger: ".Logo-Img", // Trigger animation when this container is in view
          start: "top 80%",     // Start animation when container is 80% down the viewport
          toggleActions: "play none none none", // Play only once
        }
      }
    );
  }, []);

  return (
    <div className="Logo-Heading">
      <h4>Our Partners</h4>
      <div className="Logo-Img">
        <img src={one} alt="" className="Logo-image ch" width="100%" />
        <img src={two} alt="" className="Logo-image fh" width="100%" />
        <img src={four} alt="" className="Logo-image hh" width="100%" />
        <img src={six} alt="" className="Logo-image the-assicials hh" width="100%" />
      </div>
    </div>
  );
}
