import React, { useState } from "react";
import image3 from "../login/image 3.svg";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import "./ForgotPassword.scss";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { resetPassword, ispending } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      if (!toast.isActive("allFieldsRequired")) {
        toast.error("All fields are required", {
          toastId: "allFieldsRequired",
        });
      }
      return;
    }

    try {
      await resetPassword(email);
    } catch (error) {
      toast.error(error.message, {
        toastId: "loginError",
      });
    }
  };

  return (
    <div>
      <div className="Login-Container">
        <div className="Login-Img">
          <img src={image3} alt="" className="Login-image" width="100%" />
        </div>
        <form className="Login-Form" onSubmit={handleSubmit}>
          <div className="Login-Start">
            <h1>Forgot Password</h1>
            <p>
              <span>Don't have an account?</span>
              <span id="Sign-up">
                <Link to="/register">Sign Up</Link>
              </span>
            </p>
            <input
              type="email"
              placeholder="Email Address"
              className="Email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="Logged-in-btn">
              {ispending ? <LogoSpinner /> : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
