import "./PostCreationPage.scss";
import PostInputField from "../PostInputField/PostInputField";

const PostCreationPage = () => {
  return (
    <div className="post-creation-page-container">
      <section className="post-creation-page-input-section">
        <PostInputField />
      </section>
    </div>
  );
};

export default PostCreationPage;
