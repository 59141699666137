import "./PublicProfile.scss";
import { BsBalloon } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import PostFeed from "../PostsFeed/PostFeed";
import { useEffect, useState } from "react";
import { formatDateOfBirth, getFullYear } from "../../utils/resolve";
import { usePublicProfile } from "../../contexts/profileContext";
import { useParams } from "react-router-dom";
import useFriendRequest from "../../hooks/useFriendRequest";
import { toast } from "react-toastify";
import { useId } from "../../utils/decode-token";
import { useFriends } from "../../contexts/friendsContext";
import { placeholder } from "../../constants/placeholder";

const FriendRequestButton = ({
  isCurrentUser,
  isFriend,
  isSentRequest,
  handleFriendRequest,
}) => {
  if (isCurrentUser || isFriend) return null;

  if (isSentRequest) {
    return (
      <button className="profile-page-add-friend-button">
        Friend Request Sent
      </button>
    );
  }

  return <button onClick={handleFriendRequest}>Add Friend</button>;
};

const PublicProfile = () => {
  const { userId } = useParams();
  const { friends, sentRequests } = useFriends();
  const [profile, setProfile] = useState(null);
  const [isSentRequest, setIsSentRequest] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const { profiles, loading } = usePublicProfile();
  const { sendFriendRequest } = useFriendRequest();
  const isCurrentUser = useId() === userId;
  const profileImg =
    profile?.image?.profile ||
    placeholder(40, 40, "png", profile?.name?.first[0]);
  const coverImg =
    profile?.image?.cover || placeholder(800, 200, "png", "background");

  const handleFriendRequest = async () => {
    try {
      await sendFriendRequest(userId);
      toast.success("Friend request sent", { toastId: "friend-request" });
      setIsSentRequest(true);
    } catch (error) {
      toast.error("Failed to send friend request", {
        toastId: "friend-request",
      });
    }
  };

  useEffect(() => {
    if (!loading && profiles.length > 0) {
      const profile = profiles.find((profile) => profile._id === userId);
      setProfile(profile);
    }
  }, [profiles, userId, loading]);

  useEffect(() => {
    if (profile) {
      const isFriend = friends.some(
        (friend) =>
          (friend.user && friend.user._id === profile._id) ||
          (friend.with && friend.with._id === profile._id)
      );
      setIsFriend(isFriend);
    }
  }, [profile, friends]);

  useEffect(() => {
    if (profile) {
      const isSentRequest = sentRequests.some(
        (request) => request.with && request.with._id === profile._id
      );
      setIsSentRequest(isSentRequest);
    }
  }, [profile, sentRequests]);

  return (
    !loading &&
    profile && (
      <div>
        <div className="private-profile-page">
          <div className="private-profile-page-container">
            <section className="private-profile-page-section">
              <div className="private-profile-details-section">
                <div className="profile-page-background">
                  <div className="curved-div">
                    <svg
                      width="100%"
                      height="300px"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0,100 C100,110 250,10 650,140 L700,00 L0,0 Z"
                        style={{ fill: "rgb(139, 1, 0)" }}
                      ></path>
                    </svg>
                  </div>

                  <div className="profile-page-edit-profile-picture-and-banner-edit">
                    <div className="profile-page-edit-background-image">
                      <div className="profile-page-edit-background-image-div">
                        <img src={coverImg} alt={"background"} />
                      </div>
                    </div>
                    <div className="private-profile-page-profile-picture">
                      <div className="profile-page-edit-profile-picture-div">
                        <img src={profileImg} alt={"profile"} />
                      </div>
                    </div>
                    <div className="private-profile-edit-profile-button">
                      <FriendRequestButton
                        isCurrentUser={isCurrentUser}
                        isFriend={isFriend}
                        isSentRequest={isSentRequest}
                        handleFriendRequest={handleFriendRequest}
                      />
                    </div>
                  </div>
                  <div className="profile-page-profile-details">
                    <div className="profile-page-profile-details-div">
                      <div className="profile-page-profile-name-and-username-div">
                        <h1 className="profile-page-profile-name">
                          {profile?.name?.first}
                        </h1>
                        <h5 className="profile-page-profile-username">
                          @{profile?.username}
                        </h5>
                      </div>
                      <div className="profile-page-profile-bio-div">
                        <span>{profile?.about}</span>
                        {profile.genotype && (
                          <span>Genotype: {profile?.genotype}</span>
                        )}
                      </div>
                      <div className="profile-page-profile-dates-div">
                        <div>
                          <BsBalloon />
                          <span>Born {formatDateOfBirth(profile?.dob)}</span>
                        </div>
                        <div>
                          <span>
                            <IoIosLink />
                          </span>
                          <span>
                            <a className="linklink" href="www.google.com">
                              www.google.com
                            </a>
                          </span>
                        </div>
                        <div>
                          <span>
                            <LuCalendarDays />
                          </span>
                          <span> Joined</span>
                          <span>{getFullYear(profile?.createdAt)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="profile-page-post-and-story-section">
                  <div className="profile-page-post-and-story-section-div">
                    <div className="profile-page-post-section">
                      <h1>Post</h1>
                      <div>
                        <PostFeed userId={userId} key={userId} />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  );
};

export default PublicProfile;
