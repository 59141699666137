import axios from "axios";
import useBackendStore from "../store/auth-store";
import { useCallback } from "react";

const useProfile = () => {
  const accessToken = useBackendStore((store) => store.accessToken);

  const API_URL = process.env.REACT_APP_API_URL;

  const searchProfiles = useCallback(
    async (query) => {
      try {
        const response = await axios.get(`${API_URL}/users`, {
          params: { q: query },
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
        }
      }
    },
    [accessToken, API_URL]
  );

  const getProfile = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/users/me`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      if (!axios.isCancel(error)) {
      }
      return null;
    }
  }, [accessToken, API_URL]);

  const editProfile = useCallback(
    async (data) => {
      try {
        const response = await axios.patch(`${API_URL}/users/me`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
        }
      }
    },
    [accessToken, API_URL]
  );

  const getPublicProfile = useCallback(
    async (userId) => {
      try {
        const response = await axios.get(`${API_URL}/users/${userId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
        }
      }
    },
    [accessToken, API_URL]
  );

  return { searchProfiles, getProfile, editProfile, getPublicProfile };
};

export default useProfile;
