import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./ChatPage.scss";
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import useChatStore from "../../store/chat-store";
import { useEffect, useCallback, useRef, useState } from "react";
import { useId } from "../../utils/decode-token";
import useBackendStore from "../../store/auth-store";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import { useNavigate } from "react-router-dom";
import "react-activity/dist/Squares.css";
import { stripHTMLTags } from "../../utils/resolve";
import { useSocket } from "../../contexts/socketContext";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import {
  CHAT_ENTER_EVENT,
  CHAT_LEAVE_EVENT,
  CHAT_MESSAGE_EVENT,
  ERROR_EVENT,
} from "../../constants/socketEvents";

const ChatPage = ({
  chatId,
  receiverId,
  profile,
  onBackClick,
  loading,
  style,
}) => {
  const { messages, addMessage, getMessages, hasMore, setHasMore } =
    useChatStore();
  const [loadingMore, setLoadingMore] = useState(false);
  const userId = useId();
  const { socket, socketLoading } = useSocket();
  const accessToken = useBackendStore((state) => state.accessToken);
  const navigate = useNavigate();
  const topRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        await getMessages({ from: userId, to: receiverId });
      } catch (error) {
        toast.error(
          "Sorry, we're having trouble loading your messages right now. Please try again later.",
          {
            toastId: "fetch-messages-error",
          }
        );
      }
    };

    fetchMessages();
  }, [getMessages, userId, receiverId]);

  const resolveDirection = useCallback(
    (message) => {
      return message.from === userId ? "outgoing" : "incoming";
    },
    [userId]
  );

  const handleBackClick = () => {
    onBackClick();
    if (socket && socket.connected) {
      socket.emit(CHAT_LEAVE_EVENT, chatId);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.emit(CHAT_ENTER_EVENT, chatId);

      socket.on(CHAT_MESSAGE_EVENT, (data, ack) => {
        const { from, message } = data;
        if (from === userId) {
          return;
        }
        addMessage({
          from,
          message,
          timestamp: new Date().toISOString(),
          direction: resolveDirection({ from }),
        });

        if (ack) {
          ack("success");
        }
      });
      socket.on(ERROR_EVENT, (error) => {
        toast.error("A connection error occurred", {
          toastId: "socket-io-error",
        });
      });
    }
  }, [accessToken, addMessage, chatId, resolveDirection, socket, userId]);

  const sendMessage = (message) => {
    if (socket && socket.connected) {
      socket.emit(CHAT_MESSAGE_EVENT, {
        to: receiverId,
        message,
      });
      addMessage({
        message: message,
        from: userId,
        timestamp: new Date().toISOString(),
        direction: resolveDirection({ from: userId }),
      });
    } else {
      toast.error("Message not sent", {
        toastId: "socket-io-error",
      });
    }
  };

  const handleScroll = useCallback(async () => {
    if (!hasMore || loadingMore) return;
    if (topRef.current && topRef.current.getBoundingClientRect().top >= 0) {
      const firstMessage = messages[0];
      if (firstMessage) {
        try {
          setLoadingMore(true);
          await getMessages({
            from: userId,
            to: receiverId,
            lastCreatedAt: firstMessage?.createdAt,
          });
        } catch (error) {
          toast.error(
            "Sorry, we're having trouble loading older messages right now. Please try again later.",
            {
              toastId: "fetch-older-messages-error",
            }
          );
        } finally {
          setLoadingMore(false);
        }
      }
    }
  }, [getMessages, hasMore, loadingMore, messages, receiverId, userId]);

  useEffect(() => {
    const messageListElement = topRef.current?.parentElement;
    if (messageListElement) {
      messageListElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (messageListElement) {
        messageListElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    setLoadingMore(false);
    setHasMore(true);
  }, [receiverId, setHasMore]);

  if (!receiverId || !profile) {
    return <ChatContainer style={style} />;
  }

  return (
    <ChatContainer style={style}>
      <ConversationHeader>
        <ConversationHeader.Back onClick={handleBackClick} />
        <Avatar
          name={profile?.name?.first}
          src={
            profile?.image?.profile ??
            placeholder(40, 40, "png", profile?.name?.first[0])
          }
          onClick={() => navigate(`/user/public-profile/${receiverId}`)}
        />
        <ConversationHeader.Content userName={profile?.name?.first} />
      </ConversationHeader>
      {socketLoading ? (
        <LogoSpinner />
      ) : (
        <MessageList loading={loading} loadingMore={loadingMore}>
          <div ref={topRef} />
          {messages &&
            messages.map((message, index) => (
              <Message
                key={index}
                model={{
                  message: message.message,
                  sentTime: message.timestamp,
                  sender: message.from,
                  direction: message.direction,
                }}
              />
            ))}
        </MessageList>
      )}
      <MessageInput
        placeholder="Type message here"
        onSend={(message) => sendMessage(stripHTMLTags(message).trim())}
        disabled={loading}
      />
    </ChatContainer>
  );
};

export default ChatPage;
