import React, { useEffect, useState } from "react";
import { FaRegComment, FaRegHeart, FaHeart } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Comment from "../comment/Comment";
import { placeholder } from "../../constants/placeholder";
import { useParams } from "react-router-dom";
import useComment from "../../hooks/useComment";
import usePost from "../../hooks/usePost";
import usePostStore from "../../store/posts-store";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import "./IndividualPostPage.scss";
import { resolveNotificationDateTime } from "../../utils/resolve";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import MediaGallery from "../MediaGallery/MediaGallery";
import { Link } from "react-router-dom";

const IndividualPostPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getPosts } = usePost();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePost, setActivePost] = useState(null);
  const posts = usePostStore((store) => store.posts);
  const setPosts = usePostStore((store) => store.setPosts);
  const likePost = usePostStore((store) => store.likePost);
  const commentPost = usePostStore((store) => store.commentPost);
  const [loadingComments, setLoadingComments] = useState(true);
  const [comments, setComments] = useState([]);
  const [refreshComments, setRefreshComments] = useState(false);

  const { getPostComments } = useComment();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const result = await getPosts();
        if (result.length > 0) {
          setPosts(result);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [getPosts, setPosts]);

  useEffect(() => {
    const currentPost = posts.find((post) => post._id === id);
    if (currentPost) {
      setActivePost(currentPost);
    }
  }, [id, posts, loading]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        setLoadingComments(true);
        const result = await getPostComments(id);
        if (result.length > 0) {
          setComments(result);
        }
      } catch (error) {
        toast.error(error.message, { toastId: "fetch-comments-error" });
      } finally {
        setLoadingComments(false);
      }
    };

    fetchComments();
  }, [getPostComments, id, refreshComments]);

  useEffect(() => {
    if (error) {
      toast.error(error.message, { toastId: "fetch-posts-error" });
    }
  }, [error]);

  const onLike = async (postId) => {
    try {
      await likePost(postId);
    } catch (error) {
      toast.error(error.message, { toastId: "like-post-error" });
    }
  };

  const addComment = async (postId, comment) => {
    try {
      await commentPost(postId, comment);
      setRefreshComments((prev) => !prev);
    } catch (error) {
      toast.error(error.message, { toastId: "comment-post-error" });
    }
  };

  if (!activePost || !activePost.user || !activePost.user.name) {
    return null;
  }

  const sharePost = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Post",
          text: "Check out this post",
          url: window.location.href,
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            toast.error(`Error: ${error}`, {
              toastId: "share-post-error",
            });
          }
        });
    } else {
      toast.info("Sharing is not supported in this browser.", {
        toastId: "share-not-supported",
      });
    }
  };

  const {
    _id,
    user,
    formattedDate,
    body,
    images,
    videos,
    commentCount,
    likeCount,
  } = activePost;
  const { first: firstName } = user.name;
  const userImg =
    user?.image?.profile ?? placeholder(40, 40, "png", firstName[0]);
  const username = user.username;

  return (
    <div className="individual-p-c">
      {loading ? (
        <section className="storyfeed-section-container">
          <div className="storyfeed-container">
            <LogoSpinner className="loading-icon" />
          </div>
        </section>
      ) : (
        <div>
          <section className="individual-post-container" key={_id}>
            <div
              className="individual-post-profile-picture"
              onClick={() => navigate(`/user/public-profile/${user._id}`)}
            >
              <p className="individual-post-profile-picture-container">
                <img src={userImg} alt="profile" />
              </p>
            </div>
            <div className="individual-post-texts-container">
              <div className="individual-post-container-name-username-date">
                <span className="individual-post-container-name">
                  {firstName}
                </span>
                <span className="individual-post-container-username">
                  <Link
                    to={`/user/public-profile/${user._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    @{username}
                  </Link>
                </span>
                <span className="individual-post-container-date">
                  {formattedDate}
                </span>
              </div>
              <div
                className="individual-post-container-post-body"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body),
                }}
              />
              {(images || videos) && (
                <div className="post-media-container">
                  <MediaGallery images={images} videos={videos} />
                </div>
              )}

              <div className="individual-post-container-like-comment">
                <div>
                  <span>
                    <FaRegComment />
                  </span>
                  <span>{commentCount ?? 0}</span>
                </div>
                <div onClick={() => onLike(_id)}>
                  <span>
                    {activePost.userLikes ? (
                      <FaHeart color="red" />
                    ) : (
                      <FaRegHeart color="grey" />
                    )}
                  </span>
                  <span>{likeCount ?? 0}</span>
                </div>
                <div onClick={sharePost}>
                  <span>
                    <LuUpload />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="individual-post-comment-section">
            <div className="individual-post-comments-div">
              {!loadingComments ? (
                <div className="post-comments">
                  <h2 className="comment-header">Comments</h2>
                  <div className="in-p-c">
                    {comments.length === 0 && <p>No comments yet..</p>}
                    {comments.map((comment) => (
                      <div key={comment._id} className="post-comment">
                        <div className="post-comment-profile-comment-user">
                          <div>
                            <img
                              src={
                                comment?.user?.image?.profile ??
                                placeholder(
                                  40,
                                  40,
                                  "png",
                                  comment.user.name.first[0]
                                )
                              }
                              alt="profile"
                              className="comment-profile-picture"
                              onClick={() =>
                                navigate(
                                  `/user/public-profile/${comment.user._id}`
                                )
                              }
                            />
                          </div>
                          <div className="oh-lord">
                            <p className="author"> {comment.user.name.first}</p>
                            <p className="comment-text">{comment.body}</p>
                          </div>
                        </div>
                        <p className="created-at">
                          {resolveNotificationDateTime(comment.createdAt)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="loading-container">
                  <LogoSpinner className="loading-icon" />
                </div>
              )}
            </div>
            <div>
              <Comment postId={id} addComment={addComment} isOpen={true} />
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default IndividualPostPage;
