import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [chatParticipants, setChatParticipants] = useState([]);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const accessToken = useBackendStore((state) => state.accessToken);
  const API_URL = process.env.REACT_APP_API_URL;

  const onChatStart = useCallback(
    async (receiverId) => {
      setPending(true);
      try {
        const response = await axios.post(
          `${API_URL}/chat`,
          {
            participantId: receiverId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setChats((prevChats) => [...prevChats, response.data]);
      } catch (error) {
        setError(error);
      } finally {
        setPending(false);
      }
    },
    [API_URL, accessToken]
  );

  useEffect(() => {
    const fetchChats = async (query = "") => {
      setPending(true);
      try {
        const response = await axios.get(`${API_URL}/chat`, {
          params: { q: query },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setChats(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setPending(false);
      }
    };
    fetchChats();
  }, [API_URL, accessToken]);

  useEffect(() => {
    const getUniqueChattedUsers = async (userId) => {
      setPending(true);
      if (chats && typeof chats === "object") {
        const users = chats.map((chat) => {
          if (chat.from === userId) {
            return chat.to;
          } else {
            return chat.from;
          }
        });

        const uniqueUsers = [...new Set(users)];
        setChatParticipants(uniqueUsers);
      }
      setPending(false);
    };
    getUniqueChattedUsers();
  }, [chats]);

  return (
    <ChatContext.Provider
      value={{ chats, chatParticipants, onChatStart, error, pending }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
