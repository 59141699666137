import "./UserHomePage.scss";
import PostFeed from "../PostsFeed/PostFeed";

const UserHomePage = () => {
  return (
    <div className="user-home-page">
      <div className="user-home-page-container">
        <section className="user-home-page-whole-section">
          <div className="user-home-page-whole-section-div">
            <div className="qwert">
              <h1>New Posts</h1>
              <p>View people's thoughts and emotions</p>
            </div>
            <section className="user-home-page-user-stories-section">
              <PostFeed />
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};
export default UserHomePage;
