import React, { useEffect, useState, useCallback, useRef } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./PostInputField.scss";
import usePost from "../../hooks/usePost";
import { toast } from "react-toastify";
import useProfile from "../../hooks/useProfile";
import { placeholder } from "../../constants/placeholder";
import { useNavigate } from "react-router-dom";

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "link",
  "list",
  "image",
  "video",
];

const Block = Quill.import("blots/block");
Block.tagName = "DIV";

Quill.register(Block, true);

function PostInputField() {
  const [editorContent, setEditorContent] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [profile, setProfile] = useState({});
  const quillRef = useRef(null);
  const { createPost, uploadImage, uploadVideo } = usePost();
  const { getProfile } = useProfile();
  const navigate = useNavigate();
  const displayedCount = charCount ? charCount + 1 : 0;

  const ProfilePicture =
    profile?.image?.profile ??
    placeholder(40, 40, "png", profile?.name?.first[0]);
  const characterLimit = 10000;
  const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 25 * 1024 * 1024;

  const handleImageUpload = useCallback(
    async (file) => {
      return toast.promise(uploadImage(file), {
        pending: "Uploading image...",
        success: "Image uploaded successfully!",
        error: "Image upload failed!",
      });
    },
    [uploadImage]
  );

  const handleVideoUpload = useCallback(
    async (file) => {
      return toast.promise(uploadVideo(file), {
        pending: "Uploading video...",
        success: "Video uploaded successfully!",
        error: "Video upload failed!",
      });
    },
    [uploadVideo]
  );

  const imageHandler = useCallback(() => {
    if (images.length >= 10) {
      toast.error("Maximum image limit reached");
      return;
    }

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > MAX_IMAGE_SIZE) {
          toast.error("Image size exceeds 5MB");
          return;
        }
        try {
          const data = await handleImageUpload(file);
          const src = data?.url;
          setImages((prev) => [...prev, src]);
        } catch (error) {
          toast.error("An error occurred while uploading the image");
        }
      }
    };

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.addEventListener("change", handleFileChange);
    input.click();
  }, [MAX_IMAGE_SIZE, handleImageUpload, images.length]);

  const videoHandler = useCallback(() => {
    if (videos.length >= 5) {
      toast.error("Maximum video limit reached");
      return;
    }

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > MAX_VIDEO_SIZE) {
          toast.error("Video size exceeds 25MB");
          return;
        }
        try {
          const data = await handleVideoUpload(file);
          const src = data?.url;
          setVideos((prev) => [...prev, src]);
        } catch (error) {
          toast.error("An error occurred while uploading the video");
        }
      }
    };

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.addEventListener("change", handleFileChange);
    input.click();
  }, [MAX_VIDEO_SIZE, handleVideoUpload, videos.length]);

  const removeImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  const removeVideo = (index) => {
    setVideos((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule("toolbar").addHandler("image", imageHandler);
      quill.getModule("toolbar").addHandler("video", videoHandler);

      quill.on("text-change", () => {
        const text = quill.getText();
        setCharCount(text.replace(/\s/g, "").length);
      });
    }
  }, [imageHandler, videoHandler]);

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile();
      if (profile) {
        setProfile(profile.user);
      }
    };

    fetchProfile();
  }, [getProfile]);

  const handleContentChange = (content, _delta, _source, editor) => {
    const text = editor.getText();
    if (text.length <= characterLimit) {
      setEditorContent(content);
      setCharCount(text.length);
    }
  };

  const clearState = () => {
    setEditorContent("");
    setImages([]);
    setVideos([]);
    setError("");
    setCharCount(0);
  };

  const handleSubmit = () => {
    const post = {
      body: editorContent.trim(),
      images,
      videos,
    };

    if (!post.body) {
      setError("Post cannot be empty");
      return;
    }

    if (charCount > characterLimit) {
      setError("Post exceeds character limit");
      return;
    }

    toast
      .promise(
        createPost(post),
        {
          pending: "Creating post...",
          success: "Post created successfully!",
          error: "An error occurred while creating the post",
        },
        { toastId: "post-creation" }
      )
      .then(() => {
        clearState();
        navigate("/user/home");
      })
      .catch();
  };

  useEffect(() => {
    if (error) {
      toast.error(error, { toastId: "post-error" });
      setError("");
    }
  }, [error, setError]);

  return (
    <>
      <div className="editor-container">
        <div className="quill-wrapper">
          <img src={ProfilePicture} alt="Profile" className="profile-picture" />
          <ReactQuill
            ref={quillRef}
            value={editorContent}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            theme="snow"
            placeholder="What's on your mind?"
          />

          <div className="image-preview">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt="preview" />
                <span
                  className="remove-button"
                  onClick={() => removeImage(index)}
                >
                  <LiaTimesSolid />
                </span>
              </div>
            ))}
          </div>

          <div className="video-preview">
            {videos.map((video, index) => (
              <div key={index} className="video-container">
                <video controls>
                  <source src={video} type="video/mp4" />
                </video>
                <span
                  className="remove-button"
                  onClick={() => removeVideo(index)}
                >
                  <LiaTimesSolid />
                </span>
              </div>
            ))}
          </div>

          <div className="createpost-border" />
          <div className="char-container">
            <div className="char-count">
              {displayedCount}/{characterLimit}
            </div>
            <div id="toolbar">
              <button className="ql-link" />
              <button className="ql-image" />
              <button className="ql-video" />
            </div>
            <div id="submit-button-post-create">
              <button onClick={handleSubmit} className="button" type="button">
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostInputField;
