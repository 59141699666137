import React, { useState } from "react";
import image3 from "../login/image 3.svg";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import "./PasswordResetPage.scss";

export default function PasswordResetPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword, ispending } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      if (!toast.isActive("allFieldsRequired")) {
        toast.error("All fields are required", {
          toastId: "allFieldsRequired",
        });
      }
      return;
    }

    if (password !== confirmPassword) {
      if (!toast.isActive("passwordsDontMatch")) {
        toast.error("Passwords do not match", {
          toastId: "passwordsDontMatch",
        });
      }
      return;
    }

    try {
      await changePassword(token, password);
      navigate("/login");
    } catch (error) {
      toast.error(error.message, {
        toastId: "loginError",
      });
    }
  };

  return (
    <div>
      <div className="Login-Container">
        <div className="Login-Img">
          <img src={image3} alt="" className="Login-image" width="100%" />
        </div>
        <form className="Login-Form" onSubmit={handleSubmit}>
          <div className="Login-Start">
            <h1>Reset Password</h1>
            <input
              type="password"
              placeholder="Password"
              className="Email-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              className="Email-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button type="submit" className="Logged-in-btn">
              {ispending ? <LogoSpinner /> : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
