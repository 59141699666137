import React from "react";
import "./LogoSpinner.scss";
import logo from "./Logorock.png";

const LogoSpinner = () => {
  return (
    <div className="logo-container-spinner">
      <img src={logo} alt="Brand Logo" className="logo-spinner" />
    </div>
  );
};

export default LogoSpinner;
