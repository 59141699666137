import donateimage from "../images/donatepagedonatesign.png"
import donateimagetwo from "../images/donatepagecircleimage.png"
import "./DonatePage.scss"
const DonatePage = () => {
    return (
        <div className="donate-page">
            <div className="donate-page-container">
                <section className="donate-page-header">
                    <div className="donate-page-header-div">
                        <section className="donate-page-header-text">
                            <div>
                                <h1>
                                    Your Donation makes a word of difference.
                                </h1>
                                <p>

                                </p>
                                <div className="donate-page-header-text-button">
                                    <button>
                                        Donate Now
                                    </button>
                                </div>

                            </div>
                        </section>
                        <section className="donate-page-header-image">
                            <div>
                                <img src={donateimage} alt="donateimage" />
                            </div>
                        </section>
                    </div>
                </section>
                <section className="donate-page-body">
                    <div className="donate-page-body-div">

                        <section className="donate-page-body-image">
                            <div>
                                <img src={donateimagetwo} alt="calltoactionwithimageofkidsjumping" />
                            </div>
                        </section>
                        <section className="donate-page-body-text">
                            <div className="donate-page-body-text-div">
                                <h1>
                                    How we use your donation
                                </h1>
                                <p>
                                    <ul>
                                        <li>
                                            Providing Free or Subsidised Medication
                                        </li>
                                        <li>
                                            Infrastructure for treatments
                                        </li>
                                        <li>
                                            Blood bank Initiative
                                        </li>
                                        <li>
                                            Play dates for warriors
                                        </li>
                                        <li>
                                            Health and Educational Outreaches
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="donate-page-footer">
                    <div className="donate-page-footer-div">
                        <div className="donate-page-footer-div-overlay">

                        </div>
                        <div className="donate-page-footer-text">
                            <section className="donate-page-footer-text-header">
                                <div className="donate-page-footer-text-header-div">
                                    <h1>
                                        You can ease a world of pain with your contribution to the cause.
                                    </h1>
                                </div>
                            </section>
                            <section className="donate-page-footer-text-buttons">
                                <div className="donate-page-footer-text-buttons-div">
                                    <button className="donate-page-footer-text-donate-button">
                                        Donate Now
                                    </button>
                                </div>
                                <div className="donate-page-footer-text-buttons-div">
                                    <a href="/volunteer">
                                        <button className="donate-page-footer-text-volunteer-button">
                                            Volunteer
                                        </button>
                                    </a>

                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default DonatePage