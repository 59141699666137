import { create } from "zustand";
import axios from "axios";
import useBackendStore from "./auth-store";

const API_URL = process.env.REACT_APP_API_URL;

const useChatStore = create((set, get) => {
  const accessToken = useBackendStore.getState().accessToken;

  return {
    messages: [],
    hasMore: true,
    setHasMore: (state) => {
      set({ hasMore: state });
    },
    addMessage: (message) => {
      set((state) => ({
        messages: [...state.messages, message],
      }));
    },
    getMessages: async ({ from, to, lastCreatedAt }) => {
      const state = get();
      if (!state.hasMore) return;

      try {
        const response = await axios.get(`${API_URL}/chat/message`, {
          params: { from, to, lastCreatedAt },
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.data.length > 0) {
          set((state) => {
            const existingIds = new Set(state.messages.map((msg) => msg._id));
            const newMessages = response.data.filter(
              (msg) => !existingIds.has(msg._id)
            );

            return {
              messages: [
                ...state.messages,
                ...newMessages.map((message) => ({
                  ...message,
                  direction: message.from === from ? "outgoing" : "incoming",
                })),
              ],
            };
          });
        } else {
          set({ hasMore: false });
        }
      } catch (error) {
        set({ hasMore: false });
      }
    },
    eraseMessages: () => {
      set({ messages: [] });
    },
  };
});

export default useChatStore;
