import React, { useCallback, useEffect, useRef } from "react";
import "./Notifications.scss";
import { useNotification } from "../../contexts/notificationsContext";
import { resolveNotificationDateTime } from "../../utils/resolve";
import { useNavigate } from "react-router-dom";
import { notificationsTypes } from "../../constants/notifications";
import { placeholder } from "../../constants/placeholder";
import LogoSpinner from "../LogoSpinner/LogoSpinner";

export default function Notifications() {
  const { notifications, loadMoreNotifications, loading } = useNotification();
  const loader = useRef(null);
  const navigate = useNavigate();

  const navigateToUser = (id) => {
    navigate(`/user/public-profile/${id}`);
  };

  const notificationNavigationMap = {
    [notificationsTypes.CHAT_MESSAGE]: (notification) => navigate(`/user/chat`),
    [notificationsTypes.NEW_FRIEND_REQUEST]: (notification) =>
      navigateToUser(notification?.data?.payload?.userId),
    [notificationsTypes.NEW_POST_LIKE]: (notification) =>
      navigate(`/user/feed/post/${notification?.data?.payload?.postId}`),
    [notificationsTypes.NEW_POST_COMMENT]: (notification) =>
      navigate(`/user/feed/post/${notification?.data?.payload?.postId}`),
    [notificationsTypes.NEW_STORY_COMMENT]: (notification) =>
      navigate(`/user/feed/story/${notification?.data?.payload?.storyId}`),
  };

  const resolveNotificationNavigation = (notification) => {
    const navigateFn = notificationNavigationMap[notification?.data?.type];
    if (navigateFn) {
      navigateFn(notification);
    }
  };

  const handleObserver = useCallback(
    (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        loadMoreNotifications();
      }
    },
    [loadMoreNotifications]
  );

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    loadMoreNotifications();
  }, [loadMoreNotifications]);

  const uniqueNotifications = Array.from(
    new Set(notifications.map((a) => a._id))
  ).map((id) => {
    return notifications.find((a) => a._id === id);
  });

  return (
    <div className="notification-parent">
      {loading ? (
        <section className="storyfeed-section-container">
          <div className="storyfeed-container">
            <LogoSpinner className="loading-icon" />
          </div>
        </section>
      ) : uniqueNotifications.length > 0 ? (
        uniqueNotifications.map((notification, index) => (
          <div className="postfeed-div" key={index}>
            <div className={`notification-card ${notification.status}`}>
              <div
                className="notification-content"
                onClick={() => resolveNotificationNavigation(notification)}
              >
                <img
                  src={
                    notification?.data?.payload?.userProfileImg ??
                    placeholder(40, 40, "png", "U")
                  }
                  className="notification-avatar"
                  alt="profile"
                />
                <div className="notification-text-and-meta-data">
                  <p className="notification-text">
                    <strong>{notification.title}</strong>
                  </p>
                  <p className="notification-meta">
                    {notification.body}
                    &nbsp;
                    {resolveNotificationDateTime(notification.createdAt)}
                  </p>
                </div>
              </div>
            </div>
            <div className="postfeed-div-live"></div>
          </div>
        ))
      ) : (
        <h3>Looks like there are no notifications at the moment.</h3>
      )}
    </div>
  );
}
