import React, { useState } from "react";
import line from "./image6.jpg";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkStrongPassword } from "../../utils/resolve";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

export default function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [genotype, setGenotype] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { signup, ispending } = useAuth();
  const navigate = useNavigate();

  const genotypes = ["AA", "AS", "SS", "AC", "SC"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = [
      firstName,
      lastName,
      username,
      email,
      dob,
      genotype,
      password,
      confirmPassword,
    ];

    if (fields.some((field) => !field)) {
      if (!toast.isActive("allFieldsRequired")) {
        toast.error("All fields are required", {
          toastId: "allFieldsRequired",
        });
      }
      return;
    }

    if (password !== confirmPassword) {
      if (!toast.isActive("passwordMismatch")) {
        toast.error("Passwords do not match", { toastId: "passwordMismatch" });
      }
      return;
    }

    if (!checkStrongPassword(password)) {
      if (!toast.isActive("weakPassword")) {
        toast.error(
          "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
          { toastId: "weakPassword" }
        );
      }
      return;
    }

    try {
      await signup(
        firstName,
        lastName,
        username,
        email,
        dob,
        genotype,
        password
      );
      navigate("/verify-email");
    } catch (error) {
      toast.error(error.message, { toastId: "signup-error" });
    }
  };

  return (
    <div>
      <div className="Register-container">
        <div className="Register-Img">
          <img
            src={line}
            alt="register-background"
            className="Volunteer-image"
            width="100%"
          />
        </div>

        <div className="Register-Form">
          <div className="Register-Text">
            <h3>Join Us</h3>
            <p> Register and Be Part of the Rock Bottom Family.</p>
          </div>
          <form className="from-form" onSubmit={handleSubmit}>
            <div className="from-form-label">
              <label>First Name</label>
              <input
                type="text"
                className="from-form-input"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Last Name</label>
              <input
                type="text"
                className="from-form-input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Username</label>
              <input
                type="text"
                className="from-form-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Email Address</label>
              <input
                type="email"
                className="from-form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Genotype</label>
              <select
                className="from-form-input form-select"
                value={genotype}
                onChange={(e) => setGenotype(e.target.value)}
              >
                <option value="" disabled>
                  Select Genotype
                </option>
                {genotypes.map((genotype) => (
                  <option key={genotype} value={genotype}>
                    {genotype}
                  </option>
                ))}
              </select>
            </div>
            <div className="from-form-label">
              <label>Date of birth</label>
              <input
                type="date"
                className="from-form-input"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Password</label>
              <input
                type="password"
                className="from-form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="from-form-label">
              <label>Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="from-form-input"
              />
            </div>
            <button type="submit" className="Register-btn">
              {ispending ? <Spinner /> : "Register"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
