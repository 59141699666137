import { useCallback } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";
import { useId } from "../utils/decode-token";

const useFriendRequest = () => {
  const accessToken = useBackendStore((store) => store.accessToken);
  const userId = useId();

  const API_URL = process.env.REACT_APP_API_URL;
  const status = {
    ACCEPTED: "accepted",
    PENDING: "pending",
    REJECTED: "rejected",
  };

  const sendFriendRequest = useCallback(
    async (userId) => {
      try {
        const response = await axios.post(
          `${API_URL}/friend`,
          { with: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const acceptFriendRequest = useCallback(
    async (requestId) => {
      try {
        const response = await axios.patch(
          `${API_URL}/friend`,
          { friend: requestId, status: status.ACCEPTED },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [API_URL, status.ACCEPTED, accessToken]
  );

  const rejectFriendRequest = useCallback(
    async (requestId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/friend?friend=${requestId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [API_URL, accessToken]
  );

  const getFriendRequests = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/friend?status=${status.PENDING}&with=${userId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }, [API_URL, status.PENDING, userId, accessToken]);

  const getSentFriendRequests = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/friend?status=${status.PENDING}&user=${userId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }, [API_URL, status.PENDING, userId, accessToken]);

  const removeFriend = useCallback(
    async (userId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/friend?friend=${userId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  return {
    sendFriendRequest,
    acceptFriendRequest,
    rejectFriendRequest,
    getFriendRequests,
    getSentFriendRequests,
    removeFriend,
  };
};

export default useFriendRequest;
