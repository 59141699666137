import { useEffect, useState } from "react";
import "./style.scss";
import useProfile from "../../hooks/useProfile";
import useFriendRequest from "../../hooks/useFriendRequest";
import { useFriends } from "../../contexts/friendsContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import { FiMessageCircle } from "react-icons/fi";
import { formatDateOfBirth, getFullYear, pluralize } from "../../utils/resolve";
import { useId } from "../../utils/decode-token";
import { BsBalloon } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import LogoSpinner from "../LogoSpinner/LogoSpinner";

const FriendRequests = () => {
  const tabs = {
    FRIENDS: "friends",
    REQUESTS: "requests",
    SUGGESTED: "suggested",
  };

  const [activeTab, setActiveTab] = useState(tabs.FRIENDS);
  const { getProfile } = useProfile();
  const { getFriendRequests, rejectFriendRequest, acceptFriendRequest } =
    useFriendRequest();

  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { friends, refresh } = useFriends();
  const [requests, setRequests] = useState([]);
  const [refreshFriends, setRefreshFriends] = useState(false);
  const navigate = useNavigate();
  const myId = useId();

  useEffect(() => {
    const savedTb = localStorage.getItem("activeTab");
    if (savedTb) {
      setActiveTab(savedTb);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (fetchFunction, setStateFunction) => {
      try {
        const data = await fetchFunction();
        setStateFunction(data);
      } catch (error) {
        setError(error);
      }
    };

    setIsLoading(true);

    Promise.all([
      fetchData(getProfile, setProfile),
      fetchData(getFriendRequests, setRequests),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [getProfile, getFriendRequests, refreshFriends]);

  const handleAccept = async (id) => {
    try {
      await acceptFriendRequest(id);
      setRefreshFriends(!refreshFriends);
      refresh();
    } catch (error) {
      setError(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await rejectFriendRequest(id);
      setRefreshFriends(!refreshFriends);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error("Oops! Something went wrong.", {
        toastId: "error",
      });
    }
  }, [error]);

  return !isLoading ? (
    <section className="frineds-friendsReques">
      <div className="friendsReques-hero-section">
        <div className="profile-page-edit-profile-picture-and-banner-edit">
          <div className="profile-page-edit-background-image">
            <div className="profile-page-edit-background-image-div">
              <img
                src={
                  profile?.user?.image?.cover ??
                  placeholder("800", "200", "png", "background")
                }
                alt="background"
              />
            </div>
          </div>
          <div className="private-profile-page-profile-picture">
            <div className="profile-page-edit-profile-picture-div">
              <img
                src={
                  profile?.user?.image?.profile ??
                  placeholder(
                    "200",
                    "200",
                    "png",
                    profile?.user?.name?.first[0]
                  )
                }
                alt="profile"
              />
            </div>
          </div>
          <div className="private-profile-edit-profile-button">
            <div onClick={() => navigate("/user/profile-page")}>
              <button>Edit Profile</button>
            </div>
          </div>
        </div>
        <div className="profile-page-profile-details">
          <div className="profile-page-profile-details-div">
            <div className="profile-page-profile-name-and-username-div">
              <h1 className="profile-page-profile-name">
                {profile?.user?.name?.first}
              </h1>
              <h5 className="profile-page-profile-username">
                @{profile?.user?.username}
              </h5>
            </div>
            <div className="profile-page-profile-bio-div">
              <span>{profile?.user?.about}</span>
            </div>
            <div className="profile-page-profile-dates-div">
              <div>
                <BsBalloon />
                <span>Born {formatDateOfBirth(profile?.user?.dob)}</span>
              </div>
              <div>
                <span>
                  <IoIosLink />
                </span>
                <span className="profile-page-profile-link">
                  <a href="www.google.com">www.google.com</a>
                </span>
              </div>
              <div>
                <span>
                  <LuCalendarDays />
                </span>
                <span> Joined</span>
                <span>{getFullYear(profile?.user?.createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="friends-toggle">
        <div className="friends-toggle-div">
          <div onClick={() => setActiveTab(tabs.FRIENDS)}>
            <h3 className={activeTab === tabs.FRIENDS ? "active" : " "}>
              {friends?.length} {pluralize("Friend", friends?.length)}
            </h3>
          </div>
          <div onClick={() => setActiveTab(tabs.REQUESTS)}>
            <h3 className={activeTab === tabs.REQUESTS ? "active" : " "}>
              {requests?.length} {pluralize("Friend Request", requests?.length)}
            </h3>
          </div>
        </div>
        <div className="content">
          {activeTab === tabs.FRIENDS && (
            <div className="friends-contents">
              {friends && friends.length > 0 ? (
                friends.map((data, index) => {
                  const user = data.user._id !== myId ? data.user : data.with;

                  return (
                    <div className="friends-contents-parent" key={index}>
                      <div className="friends-contents-child-div">
                        <div
                          className="friends-contents-child-one"
                          onClick={() =>
                            navigate(`/user/public-profile/${user._id}`)
                          }
                        >
                          <img
                            src={
                              user?.image?.profile ||
                              placeholder(200, 200, "png", user?.name?.first[0])
                            }
                            alt={user?.name?.first}
                          />
                        </div>
                        <div className="friends-contents-child-two">
                          <h3>{user?.name?.first}</h3>
                          <p>@{user?.username}</p>
                        </div>
                      </div>
                      <div
                        className="friends-contents-icon"
                        onClick={() =>
                          navigate(`/user/chat`, {
                            state: { receiverId: user?._id },
                          })
                        }
                      >
                        <FiMessageCircle />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h3>NO DATA FOUND!</h3>
                </div>
              )}
            </div>
          )}

          {activeTab === tabs.REQUESTS && (
            <div className="requests-contents">
              {requests && requests.length > 0 ? (
                requests.map((data, index) => (
                  <div className="requests-contents-parent" key={index}>
                    <div className="requests-contents-child-div">
                      <div
                        className="requests-contents-child-one"
                        onClick={() =>
                          navigate(`/user/public-profile/${data.user._id}`)
                        }
                      >
                        <img
                          src={
                            data?.user?.image?.profile ||
                            placeholder(
                              200,
                              200,
                              "png",
                              data?.user?.name?.first[0]
                            )
                          }
                          alt={data?.user?.name?.first}
                        />
                      </div>
                      <div className="requests-contents-child-two">
                        <h3>{data?.user?.name?.first}</h3>
                        <p>@{data?.user?.username}</p>
                      </div>
                    </div>
                    <div className="requests-contents-child-button">
                      <div
                        onClick={() => handleAccept(data?._id)}
                        className="requests-contents-child-button-one"
                      >
                        Accept Request
                      </div>
                      <div
                        onClick={() => handleDelete(data?._id)}
                        className="requests-contents-child-button-two"
                      >
                        Decline
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3>NO DATA !!!!!!</h3>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <div className="loading-container">
      <LogoSpinner className="loading-icon" />
    </div>
  );
};

export default FriendRequests;
