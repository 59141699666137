import "./App.scss";
import Home from "./components/Home";
import Navbar from "./components/navbar/Navbar";
import "./components/navbar/navbar.scss";
import "./components/Home.scss";
import "./components/about-us/about.scss";
import "./components/volunteer/volunteer.scss";
import "./components/Register/register.scss";
import "./components/login/login.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toastConfig } from "./config/toast";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import About from "./components/about-us/About";
import Footer from "./components/Footer";
import Volunteer from "./components/volunteer/Volunteer";
import Register from "./components/Register/Register";
import Login from "./components/login/Login";
import UserHomePage from "./components/Userhomepage/UserHomePage";
import DonatePage from "./components/donate/DonatePage";
import ProfilePage from "./components/profile-page/ProfilePage";
import EmailPage from "./components/emailPage/EmailPage";
import PrivateProfile from "./components/private-profile/PrivateProfile";
import PostCreationPage from "./components/PostCreationPage/PostCreationPage";
import PublicProfile from "./components/PublicProfile/PublicProfile";
import PostFeed from "./components/PostsFeed/PostFeed";
import { PublicProfileProvider } from "./contexts/profileContext";
import { FriendsProvider } from "./contexts/friendsContext";
import { ChatProvider } from "./contexts/chatsContext";
import SideBar from "./layout/SideBar";
import { useAuthContext } from "./contexts/authContext";
import { tokenVerificationMessages as tvm } from "./constants/messages";
import FriendRequests from "./components/friendsorfriendrequests/Friendrequests";
import { NotificationProvider } from "./contexts/notificationsContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import ChatFeed from "./components/ChatFeed/ChatFeed";
import Notifications from "./components/notifications/Notifications";
import UserFooterNav from "./components/UserFooterNav/UserFooterNav";
import IndividualPostPage from "./components/IndividualPostPage/IndividualPostPage";
import UserSearchPage from "./components/UserSearchPage/UserSearchPage";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import PasswordResetPage from "./components/PasswordResetPage/PasswordResetPage";
import ChatPage from "./components/chatPage/ChatPage";
import { SocketProvider } from "./contexts/socketContext";
import axios from "axios";

const protectedRoute = (path, component, tokenStatus) => {
  return (
    <Route
      path={path}
      element={tokenStatus === tvm.OK ? component : <Navigate to="/login" />}
    />
  );
};

function App() {
  axios.defaults.headers.common["ngrok-skip-browser-warning"] = "69420";
  const { loading, tokenStatus } = useAuthContext();
  return (
    <ErrorBoundary>
      {!loading && (
        <PublicProfileProvider>
          <FriendsProvider>
            <SocketProvider>
              <ChatProvider>
                <NotificationProvider>
                  <BrowserRouter>
                    <div className="app-container">
                      <div className="App">
                        <Navbar />
                        <div>
                          <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/error" element={<ErrorPage />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/volunteer" element={<Volunteer />} />
                            <Route
                              path="/register"
                              element={
                                tokenStatus === tvm.OK ? (
                                  <Navigate to="/user/home" />
                                ) : tokenStatus === tvm.UNVERIFIED ? (
                                  <Navigate to="/verify-email" />
                                ) : (
                                  <Register />
                                )
                              }
                            />
                            <Route
                              path="/login"
                              element={
                                tokenStatus === tvm.OK ? (
                                  <Navigate to="/user/home" />
                                ) : tokenStatus === tvm.UNVERIFIED ? (
                                  <Navigate to="/verify-email" />
                                ) : (
                                  <Login />
                                )
                              }
                            />
                            <Route
                              path="/verify-email"
                              element={
                                tokenStatus === tvm.UNVERIFIED ? (
                                  <EmailPage />
                                ) : (
                                  <Navigate to="/login" />
                                )
                              }
                            />
                            <Route
                              path="/forgot-password"
                              element={
                                tokenStatus === tvm.OK ? (
                                  <Navigate to="/user/home" />
                                ) : (
                                  <ForgotPassword />
                                )
                              }
                            />
                            <Route
                              path="/reset-password/:token"
                              element={
                                tokenStatus === tvm.OK ? (
                                  <Navigate to="/user/home" />
                                ) : (
                                  <PasswordResetPage />
                                )
                              }
                            />
                            <Route path="/donate" element={<DonatePage />} />
                            <Route path="/user/" element={<SideBar />}>
                              {protectedRoute(
                                "home",
                                <UserHomePage />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "profile-page",
                                <ProfilePage />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "profile",
                                <PrivateProfile />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "friends",
                                <FriendRequests />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "chat",
                                <ChatFeed />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "chat/:userId",
                                <ChatPage />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "create-post",
                                <PostCreationPage />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "public-profile/:userId",
                                <PublicProfile />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "feed/posts",
                                <PostFeed />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "feed/post/:id",
                                <IndividualPostPage />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "notifications",
                                <Notifications />,
                                tokenStatus
                              )}
                              {protectedRoute(
                                "search",
                                <UserSearchPage />,
                                tokenStatus
                              )}
                            </Route>
                          </Routes>
                        </div>
                        {tokenStatus === tvm.OK ? (
                          <UserFooterNav />
                        ) : (
                          <Footer />
                        )}
                      </div>
                      <ToastContainer {...toastConfig} />
                    </div>
                  </BrowserRouter>
                </NotificationProvider>
              </ChatProvider>
            </SocketProvider>
          </FriendsProvider>
        </PublicProfileProvider>
      )}
    </ErrorBoundary>
  );
}

export default App;
