import axios from "axios";
import useBackendStore from "../store/auth-store";
import { useCallback } from "react";

const usePost = () => {
  const accessToken = useBackendStore((store) => store.accessToken);

  const API_URL = process.env.REACT_APP_API_URL;

  const getPosts = useCallback(
    async (lastCreatedAt, userId) => {
      try {
        const params = {
          ...(lastCreatedAt && { lastCreatedAt }),
          ...(userId && { user: userId }),
        };
        const response = await axios.get(`${API_URL}/post`, {
          params,
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const createPost = useCallback(
    async (post) => {
      try {
        const response = await axios.post(`${API_URL}/post`, post, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const deletePost = useCallback(
    async (postId) => {
      try {
        await axios.delete(`${API_URL}/post/${postId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const uploadImage = useCallback(
    async (image) => {
      try {
        const formData = new FormData();
        formData.append("file", image);

        const response = await axios.post(`${API_URL}/users/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return response.data;
      } catch (error) {
        throw new Error("An error occurred while uploading the image.");
      }
    },
    [accessToken, API_URL]
  );

  const uploadVideo = useCallback(
    async (video) => {
      try {
        const formData = new FormData();
        formData.append("file", video);

        const response = await axios.post(`${API_URL}/users/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return response.data;
      } catch (error) {
        throw new Error("An error occurred while uploading the video.");
      }
    },
    [accessToken, API_URL]
  );

  return { getPosts, createPost, deletePost, uploadImage, uploadVideo };
};

export default usePost;
