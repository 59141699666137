import React, { useCallback, useState } from "react";
import { FaHeart, FaRegComment, FaRegHeart } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import { shortenStory } from "../../utils/resolve";
import MediaGallery from "../MediaGallery/MediaGallery";
import "./Post.scss";

const Post = ({ post, onLike, showDelete = false, onDelete }) => {
  const [isSharing, setIsSharing] = useState(false);
  const navigate = useNavigate();

  const sharePost = useCallback(
    (id) => {
      if (isSharing) {
        toast.info("Please wait for the current share operation to complete", {
          toastId: "share-in-progress",
        });
        return;
      }

      if (navigator.share) {
        setIsSharing(true);
        navigator
          .share({
            title: "Share Post",
            text: "Check out this post",
            url: `${window.location.origin}/user/feed/post/${id}`,
          })
          .catch((error) => {
            if (error.name !== "AbortError") {
              toast.error(`Error: ${error}`, {
                toastId: "share-post-error",
              });
            }
          })
          .finally(() => {
            setIsSharing(false);
          });
      } else {
        toast.info("Sharing is not supported in this browser.", {
          toastId: "share-not-supported",
        });
      }
    },
    [isSharing]
  );

  if (!post || !post.user || !post.user.name) {
    return null;
  }

  const {
    _id,
    user,
    formattedDate,
    body,
    images,
    videos,
    commentCount,
    likeCount,
  } = post;
  const { first: firstName } = user.name;
  const userImg =
    user?.image?.profile ?? placeholder(40, 40, "png", firstName[0]);

  const isLengthyPost = body.length > 500; // Corrected to check the length of the body
  const username = user.username;
  const profileUrl = `/user/public-profile/${user._id}`;
  const mediaExists = images.length !== 0 || videos.length !== 0;

  return (
    <section className="individual-post-container" key={_id}>
      <ProfilePicture userImg={userImg} profileUrl={profileUrl} />
      <div className="individual-post-texts-container">
        <PostHeader
          firstName={firstName}
          username={username}
          profileUrl={profileUrl}
          formattedDate={formattedDate}
        />
        <PostBody
          body={body}
          isLengthyPost={isLengthyPost}
          mediaExists={mediaExists}
          images={images}
          videos={videos}
          postId={_id}
        />
        <PostActions
          postId={_id}
          commentCount={commentCount}
          likeCount={likeCount}
          userLikes={post.userLikes}
          onLike={onLike}
          onDelete={onDelete}
          showDelete={showDelete}
          sharePost={sharePost}
          navigate={navigate}
        />
      </div>
    </section>
  );
};

const ProfilePicture = ({ userImg, profileUrl }) => {
  const navigate = useNavigate();
  return (
    <div className="individual-post-profile-picture">
      <p className="individual-post-profile-picture-container">
        <img onClick={() => navigate(profileUrl)} src={userImg} alt="profile" />
      </p>
    </div>
  );
};

const PostHeader = ({ firstName, username, profileUrl, formattedDate }) => (
  <div className="individual-post-container-name-username-date">
    <Link className="individual-post-container-name" to={profileUrl}>
      {firstName}
    </Link>
    <Link className="individual-post-container-username" to={profileUrl}>
      @{username}
    </Link>
    <span className="individual-post-container-date">{formattedDate}</span>
  </div>
);

const PostBody = ({
  body,
  isLengthyPost,
  mediaExists,
  images,
  videos,
  postId,
}) => (
  <div className="individual-post-container-post-text">
    <div className="post-container">
      <p className="post-body">{shortenStory(body)}</p>
      {isLengthyPost && (
        <span className="post-read-more">
          <Link to={`/user/feed/post/${postId}`}>Read more</Link>
        </span>
      )}
      {mediaExists && <MediaGallery images={images} videos={videos} />}
    </div>
  </div>
);

const PostActions = ({
  postId,
  commentCount,
  likeCount,
  userLikes,
  onLike,
  onDelete,
  showDelete,
  sharePost,
  navigate,
}) => (
  <div className="individual-post-container-like-comment">
    <div onClick={() => navigate(`/user/feed/post/${postId}`)}>
      <span>
        <FaRegComment />
      </span>
      <span>{commentCount ?? 0}</span>
    </div>
    <div onClick={() => onLike(postId)}>
      <span>
        {userLikes ? <FaHeart color="red" /> : <FaRegHeart color="grey" />}
      </span>
      <span>{likeCount ?? 0}</span>
    </div>
    <div onClick={() => sharePost(postId)}>
      <span>
        <LuUpload />
      </span>
    </div>
    {showDelete && (
      <div onClick={() => onDelete(postId)}>
        <span>
          <RiDeleteBinLine />
        </span>
      </div>
    )}
  </div>
);

export default Post;
