import "./PostFeed.scss";
import { useState, useRef, useCallback } from "react";
import usePost from "../../hooks/usePost";
import Post from "../Post/Post";
import usePostStore from "../../store/posts-store";
import { convertToShortDate } from "../../utils/resolve";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";

const PostFeed = ({ userId, showDelete = false }) => {
  const { getPosts } = usePost();
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const lastCreatedAtRef = useRef(null);
  const posts = usePostStore((store) => store.posts);
  const likePost = usePostStore((store) => store.likePost);
  const setPosts = usePostStore((store) => store.setPosts);
  const deletePost = usePostStore((store) => store.deletePost);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  const fetchPosts = async () => {
    try {
      const result = await getPosts(lastCreatedAtRef.current, userId);
      if (result.length > 0) {
        lastCreatedAtRef.current = result[result.length - 1].createdAt;
        setPosts(result);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  const refreshPosts = async () => {
    try {
      lastCreatedAtRef.current = null;
      setHasMore(true);
      await fetchPosts();
    } catch (error) {
      setError(error);
    }
  };

  const { loading, observerTarget } = useInfiniteScroll({
    fetchData: fetchPosts,
    hasMore,
    threshold: 0.9,
  });

  const stripHTMLTags = (str) =>
    str.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "");

  const formatDate = (dateString) => convertToShortDate(dateString);

  const cleanedResponse = posts
    ? posts
        .map((item) => ({
          ...item,
          body: stripHTMLTags(item.body),
          featuredImage: item.images,
          formattedDate: formatDate(item.createdAt),
        }))
        .filter((item) => !userId || item.user._id === userId)
    : [];

  const handleDeleteClick = (postId) => {
    setPostToDelete(postId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    toast
      .promise(
        deletePost(postToDelete),
        {
          pending: "Deleting post...",
          success: "Post deleted successfully!",
          error: "Failed to delete post",
        },
        {
          toastId: "delete-post",
        }
      )
      .then(() => {
        setPosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== postToDelete)
        );
        setIsModalOpen(false);
        refreshPosts();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onDelete = useCallback((postId) => {
    handleDeleteClick(postId);
  }, []);

  if (error) {
    return (
      <section className="postfeed-section-container">
        <div className="postfeed-container">
          <p>Error: {error.message}</p>
        </div>
      </section>
    );
  }

  return (
    <section className="postfeed-section-container">
      <div className="postfeed-container">
        {!loading && cleanedResponse.length === 0 ? (
          <p>No post created yet..</p>
        ) : (
          cleanedResponse.map((details, index) => (
            <div className="postfeed-div" key={index}>
              <Post
                post={details}
                onLike={likePost}
                showDelete={showDelete}
                onDelete={() => onDelete(details._id)}
              />
              <div className="postfeed-div-live" />
            </div>
          ))
        )}

        <div ref={observerTarget} />
        {loading && (
          <section className="postfeed-section-container">
            <div className="postfeed-container">
              <LogoSpinner className="loading-icon" />
            </div>
          </section>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this post?"
      />
    </section>
  );
};

export default PostFeed;
