import React from "react";
import MediaSwiper from "../MediaSwiper/MediaSwiper";
import "./MediaGallery.scss";

const MediaGallery = ({ images, videos }) => {
  const _images = images?.map((image) => ({ src: image, type: "image" })) || [];
  const _videos = videos?.map((video) => ({ src: video, type: "video" })) || [];
  const media = [..._images, ..._videos];

  if (media.length === 0) return null;

  return (
    <div className="media-gallery">
      <MediaSwiper media={media} />
    </div>
  );
};

export default MediaGallery;
