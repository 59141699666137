import { getMessaging, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { Outlet } from "react-router";
import UserPageNav from "../components/user-page-nav/UserPageNav";
import "./sidebar.scss";
import { useNotification } from "../contexts/notificationsContext";
import { toast } from "react-toastify";

export default function SideBar({ isSidebarVisible }) {
  const messaging = getMessaging();
  const { setNotifications } = useNotification();

  useEffect(() => {
    onMessage(messaging, (payload) => {
      toast.info(payload.notification.body, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setNotifications((prevNotifications) => [...prevNotifications, payload]);
    });
  }, [messaging, setNotifications]);

  return (
    <div className="bar">
      <div className={`sidebar ${isSidebarVisible ? "visible" : ""}`}>
        <UserPageNav />
      </div>
      <div className={`main-content ${isSidebarVisible ? "slide" : ""}`}>
        <Outlet />
      </div>
    </div>
  );
}
