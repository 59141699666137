const CONNECT_EVENT = "connect";
const NOTIFICATION_EVENT = "notification-unread";
const CHAT_UNREAD_EVENT = "chat-unread";
const ERROR_EVENT = "connect_error";
const CHAT_MESSAGE_EVENT = "chat-message";
const CHAT_ERROR_EVENT = "exception";
const CHAT_ENTER_EVENT = "chat-enter";
const CHAT_LEAVE_EVENT = "chat-leave";

export {
  CONNECT_EVENT,
  NOTIFICATION_EVENT,
  CHAT_UNREAD_EVENT,
  ERROR_EVENT,
  CHAT_MESSAGE_EVENT,
  CHAT_ERROR_EVENT,
  CHAT_ENTER_EVENT,
  CHAT_LEAVE_EVENT,
};
