import React from "react";
import { useOTPFormStore } from "./OTPForm.state";
import { toast } from "react-toastify";
import "./styles.scss";
import { useAuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OTPForm = ({ accessToken }) => {
  const {
    code,
    setCode,
    sendOTP,
    verifyOTP,
    disableResend,
    countdown,
    error,
    setError,
  } = useOTPFormStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error("Oops! Something went wrong. Please try again later.", {
        toastId: "otp-error",
      });
      setError(null);
    }
  }, [error, setError]);

  const { refreshAuth } = useAuthContext();

  const sendOTPAndNotify = async () => {
    toast.promise(
      sendOTP(accessToken),
      {
        pending: "Sending OTP...",
        success: "OTP sent successfully",
        error: "Failed to send OTP",
      },
      {
        toastId: "send-otp",
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.promise(
      verifyOTP(accessToken),
      {
        pending: "Verifying OTP...",
        success: "OTP verified successfully",
        error: "Failed to verify OTP",
      },
      {
        toastId: "verify-otp",
      }
    );
    await refreshAuth();
    navigate("/user/home");
  };

  return (
    <form onSubmit={handleSubmit} className="otp-form">
      <div className="input-div">
        <input
          type="text"
          placeholder="Enter OTP here"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="otp-input"
        />
      </div>
      <div className="button-div">
        <button    type="button"
          onClick={sendOTPAndNotify}
          disabled={disableResend}
          className="resend-button">
          Send OTP
        </button>
        <button
       
           type="submit" className="verify-button"
        >
          Verify
        </button>
      </div>
      <div className="countdown-timer">
        <span>{disableResend && countdown}</span>
      </div>
    </form>
  );
};

export default OTPForm;
